<template>
  <v-row justify="center" v-if="shower === true">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="700px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("keydetail.details") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-information</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:525px">
          <!-- <div class="text-center">
           <v-progress-circular
              v-if="createprogress === true"
              indeterminate
              :color="color.theme"
              size="70"             
            >
           <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-progress-circular>
          </div> -->
          <div>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12>
                <v-subheader>
                  {{ $t("keydetail.general") }}
                  <v-divider class="mx-4"></v-divider>
                </v-subheader>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12 class="pl-4 pt-4">
                <v-icon
                  x-large
                  :color="filedata.file_icon[1]"
                  style="font-weight: 100"
                  >{{ filedata.file_icon[0] }}</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 pt-4"
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filename") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4 pt-4"
                ><p>{{ dataObj.name }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filesize") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>
                  {{ calculatesize(dataObj.size) }}
                  <v-progress-circular
                    v-if="load_size === true"
                    indeterminate
                    :size="15"
                    :width="2"
                    class="ma-0 mt-n1 ml-2"
                  ></v-progress-circular></p
              ></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filetype") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ dataObj.type || dataObj.data_type }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filedate") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ calculatedate(dataObj.cre_dtm) }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.owner") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>
                  {{
                    $t("default") === "th" ? dataObj.name_th : dataObj.name_eng
                  }}
                </p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.path") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ dataObj.full_path }}</p></v-flex
              >
            </v-layout>
            <v-layout
              xs12
              lg12
              row
              wrap
              v-if="dataAccountActive.type === 'Business'"
            >
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.permission") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ permission(filedata.file_permission_2) }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.expiration") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4 "
                ><p>{{ calculatedate(dataObj.expiration_time) }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">Hashtag</p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4 ">
                <v-btn
                  x-small
                  outlined
                  class="elevation-0 text-right"
                  color="error"
                  :disabled="
                    createprogress ||
                      filedata.editing_status !== 'N' ||
                      filedata.file_permission_2 === '01' ||
                        filedata.file_permission_2 === '02'
                  "
                  @click="opendialoghashtag()"
                  ><v-icon
                    class="ma-1"
                    dark
                    small
                    v-if="filedata.hashtag !== ''"
                    >edit</v-icon
                  >{{ $t("edithashtag.edit") }}</v-btn
                ></v-flex
              >
            </v-layout>
            <v-layout>
              <v-flex>
                <div v-if="dataObj.hashtag == ''">-</div>
                <div v-else>
                  <v-chip
                    small
                    class="ma-1"
                    v-for="item in dataObj.hashtag"
                    :key="item"
                    >#{{ item }}</v-chip
                  >
                </div>
              </v-flex>
            </v-layout>
          </div>

          <!-- <v-list two-line subheader> -->
          <!--   <v-subheader>
              {{ $t("keydetail.general") }}
              <v-divider class="mx-4"></v-divider>
            </v-subheader>
            <v-list-item-icon style="padding-left: 4%">
              <v-icon x-large :color="filedata.file_icon[1]" style="font-weight: 100">{{ filedata.file_icon[0] }}</v-icon>
            </v-list-item-icon>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filename") }}</v-list-item-title>
                <v-list-item-subtitle :title="dataObj.name">{{ dataObj.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filesize") }}</v-list-item-title>
                <v-list-item-subtitle>{{ calculatesize(dataObj.size) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filetype") }}</v-list-item-title>
                <v-list-item-subtitle>{{ dataObj.type || dataObj.data_type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filedate") }}</v-list-item-title>
                <v-list-item-subtitle>{{ calculatedate(dataObj.cre_dtm) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.owner") }}</v-list-item-title>
                <v-list-item-subtitle v-if="$t('default') === 'th'">{{ dataObj.name_th }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ dataObj.name_eng }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.path") }}</v-list-item-title>
                <v-list-item-subtitle :title="dataObj.full_path">{{ dataObj.full_path }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="dataAccountActive.type === 'Business'">
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.permission") }}</v-list-item-title>
                <v-list-item-subtitle>{{ permission(filedata.file_permission) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
             <v-list-item-content>
                <v-list-item-title>วันหมดอายุไฟล์</v-list-item-title>
                <v-list-item-subtitle>{{calculatedate(dataObj.expiration_time)}}</v-list-item-subtitle>
             </v-list-item-content>
            </v-list-item>-->
          <!-- <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Hashtag</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
                <div v-if="dataObj.hashtag == ''">-</div>
                <div v-else><v-chip small class="ma-1" v-for="item in dataObj.hashtag" :key="item">#{{item}}</v-chip></div>
              </v-list-item-content>
                <v-list-item-action>
                 <v-btn x-small outlined class="elevation-0 text-right" color="error" :disabled="createprogress || filedata.editing_status !== 'N' || filedata.file_permission.edit !== 'True'" @click="opendialoghashtag()"><v-icon class="ma-1" dark small v-if="filedata.hashtag !== ''">edit</v-icon>{{ $t("edithashtag.edit")}}</v-btn>
                </v-list-item-action>
            </v-list-item>

          </v-list>  -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            :dark="color.darkTheme"
            @click="$emit('closedkeydetail'), (dataObj = {})"
            >{{ $t("keydetail.closeDialog") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="px-5 pt-4 pb-1">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-2">
              <span :style="headerPage">{{ $t("keydetail.details") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closedkeydetail'), (dataObj = {})"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height:420px;">
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filename") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ dataObj.name }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filesize") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>
                {{ calculatesize(dataObj.size) }}
                <v-progress-circular
                  v-if="load_size === true"
                  indeterminate
                  :size="15"
                  :width="2"
                  class="ma-0 mt-n1 ml-2"
                ></v-progress-circular></p
            ></v-flex>
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filetype") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ dataObj.type || dataObj.data_type }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filedate") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ calculatedate(dataObj.cre_dtm) }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.owner") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>
                {{
                  $t("default") === "th" ? dataObj.name_th : dataObj.name_eng
                }}
              </p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.path") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ dataObj.full_path }}</p></v-flex
            >
          </v-layout>
          <v-layout
            xs12
            lg12
            row
            wrap
            v-if="dataAccountActive.type === 'Business'"
          >
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.permission") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4">
              <v-chip
                small
                class="mt-n1"
                :style="$vuetify.theme.dark ? 'background: #555;' : 'background: #E4ECF9; color: #1B5DD6;'"
                >{{ permission(filedata.file_permission_2) }}</v-chip
              >
            </v-flex>
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.expiration") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ calculatedate(dataObj.expiration_time) }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">Hashtag</p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4">
              <v-btn
                x-small
                outlined
                class="elevation-0 text-right"
                color="error"
                :disabled="
                  createprogress ||
                    filedata.editing_status !== 'N' ||
                    filedata.file_permission_2 === '01' ||
                      filedata.file_permission_2 === '02'
                "
                @click="opendialoghashtag()"
                ><v-icon class="ma-1" dark small v-if="filedata.hashtag !== ''"
                  >edit</v-icon
                >{{ $t("edithashtag.edit") }}</v-btn
              ></v-flex
            >
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 class="pl-4">
              <div v-if="dataObj.hashtag == ''">-</div>
              <div v-else>
                <v-chip
                  small
                  class="ma-1"
                  v-for="item in dataObj.hashtag"
                  :key="item"
                  >#{{ item }}</v-chip
                >
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogeditdeletehashtag
      :show="opendialogeditdeletehashtag"
      :filedata="filedata"
      :listcheckhashtag="dataObj"
      @close="opendialogeditdeletehashtag = false"
      @reload="reshowhashtag"
    ></dialogeditdeletehashtag>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
// import gbfGenerate from "../globalFunctions/generateAuthorize";
import dialogeditdeletehashtag from "../optional/dialog-editdeletehashtag";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  components: { dialogeditdeletehashtag },
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      // loaddataprogress:false,
      opendialogeditdeletehashtag: false,
      filename: "",
      createprogress: false,
      dataObj: {},
      load_size: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          let filename = this.filedata.file_name.split(".");

          console.log(filename);

          let i;
          let namewithoutextension = "";
          for (i = 0; i < filename.length; i++) {
            if (i !== filename.length - 1) {
              namewithoutextension += filename[i];
            } else if (filename.length - 1 === 0) {
              namewithoutextension += filename[i];
            }
          }
          this.fn_loaddatadetail();
          this.filename = namewithoutextension;
          this.$emit("closeDrag");
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closechangename");
        }
      },
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  methods: {
    reshowhashtag() {
      this.fn_loaddatadetail();
    },
    opendialoghashtag() {
      this.opendialogeditdeletehashtag = true;
    },
    // แปลงค่าวัน
    fn_dateFormat() {
      const datetime = Date.now();
      var d = new Date(datetime);
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var year = d.getFullYear().toString();
      var month = months[d.getMonth()];
      var day = d.getDate();
      var hour = d.getHours();
      var min = d.getMinutes();

      console.log(
        year + "-" + month + "-" + day + "T" + hour + ":" + min + "_" + "Bearer"
      );
      let newdatetime =
        year +
        "-" +
        month +
        "-" +
        day +
        "T" +
        hour +
        ":" +
        min +
        "_" +
        "Bearer";

      return newdatetime;
    },
    // Authorization header fix อยู่
    fn_getCodeAuthen() {
      let decode = window.atob(
        process.env.VUE_APP_BEARER_TOKEN
      );
      let str =
        this.fn_dateFormat() +
        " " +
        process.env.VUE_APP_BEARER_TOKEN;
      console.log(str, decode);
      let code = window.btoa(str);
      return code;
    },
    // โหลดข้อมูลไฟล์และโฟลเดอร์
    async fn_loaddatadetail() {
      this.createprogress = true;
      // console.log("filedata:", this.filedata);
      // โหลดข้อมูลโฟลเดอร์
      if (this.filedata.file_type === "folder") {
        let payload = {
          folder_id: this.filedata.file_id,
          account_id: this.dataAccountId,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/info_data_folder",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            this.load_size = true;
            if (response.data.status === "OK") {
              this.dataObj = response.data.result;
              this.createprogress = false;
              // วนลูปบวกขนาดของโฟลเดอร์
              for (let i = 0; i < this.dataObj.url_size_folder.length; i++) {
                this.axios
                  .get(this.dataObj.url_size_folder[i].url, {
                    headers: { Authorization: auth.code },
                  })
                  .then((response) => {
                    if (response.data.status === "OK") {
                      // ค่าเป็น string ต้องแปลงค่าก่อนบวก
                      this.dataObj.size =
                        parseInt(this.dataObj.size) +
                        parseInt(response.data.result.size_folder);
                      // เช็คเพื่อปิดตัวโหลดขนาดไฟล์
                      if (i + 1 === this.dataObj.url_size_folder.length) {
                        this.load_size = false;
                      }
                    } else {
                      this.load_size = false;
                      Toast.fire({
                        icon: "error",
                        title: this.$t("keydetail.cannotopendatafile"),
                      });
                    }
                  })
                  .catch((e) => {
                    this.load_size = false;
                    Toast.fire({
                      icon: "error",
                      title: this.$t("keydetail.cannotopendatafile"),
                    });
                    // console.log("error data_size_folder", e);
                  });
              }
            } else {
              this.createprogress = false;
              this.load_size = false;
              Toast.fire({
                icon: "error",
                title: this.$t("keydetail.cannotopendatafile"),
              });
            }
          })
          .catch((e) => {
            this.createprogress = false;
            // console.log("error info_data_folder", e);
            Toast.fire({
              icon: "error",
              title: this.$t("keydetail.cannotopendatafile"),
            });
          });
        // โหลดข้อมูลไฟล์
      } else {
        let payload = {
          data_id: this.filedata.file_id,
          data_type: this.filedata.file_type == "folder" ? "folder" : "file",
          account_id: this.dataAccountId,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/info_data",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("response dialog keydetail", response);
            if (response.data.status === "OK") {
              this.dataObj = response.data.result;
              console.log("this.dataObj", this.dataObj);
              this.createprogress = false;
              // setTimeout(() => {
              //   this.createprogress = false;
              // }, 2500);
            } else {
              this.createprogress = false;
              Toast.fire({
                icon: "error",
                title: this.$t("keydetail.cannotopendatafile"),
              });
            }
          })
          .catch((e) => {
            this.createprogress = false;
            // console.log("error fn_loaddatadetail (file)", e);
            Toast.fire({
              icon: "error",
              title: this.$t("keydetail.cannotopendatafile"),
            });
          });
      }
    },
    calculatesize(parameter) {
      if (parameter === undefined) {
        return " ";
      }
      // console.log("parameter",parameter);
      let size;
      if (parameter >= 1099511992567 && parameter <= 1125899906842624) {
        size = (parameter / 1099511992567).toFixed(2) + " TB";
      } else if (parameter >= 1073741824 && parameter < 1099511992567) {
        size = (parameter / 1073741824).toFixed(2) + " GB";
      } else if (parameter >= 1048576 && parameter < 1073741824) {
        size = (parameter / 1048576).toFixed(2) + " MB";
      } else if (parameter >= 1024 && parameter < 1048576) {
        size = (parameter / 1024).toFixed(2) + " KB";
      } else if (parameter === "-") {
        size = parameter;
      } else {
        size = parameter + " B";
      }

      return size;
    },
    calculatedate(parameter) {
      let date = [];
      let month, finalDate;
      if (parameter === "00000000000000") {
        return "-";
      } else if (parameter) {
        date.push(parameter[0] + parameter[1] + parameter[2] + parameter[3]);
        date.push(parameter[4] + parameter[5]);
        date.push(parameter[6] + parameter[7]);
        date.push(parameter[8] + parameter[9]);
        date.push(parameter[10] + parameter[11]);
        date.push(parameter[12] + parameter[13]);
        var months = [
          this.$t("keydetail.month.jan"),
          this.$t("keydetail.month.feb"),
          this.$t("keydetail.month.mar"),
          this.$t("keydetail.month.apr"),
          this.$t("keydetail.month.may"),
          this.$t("keydetail.month.jun"),
          this.$t("keydetail.month.jul"),
          this.$t("keydetail.month.aug"),
          this.$t("keydetail.month.sep"),
          this.$t("keydetail.month.oct"),
          this.$t("keydetail.month.nov"),
          this.$t("keydetail.month.dec"),
        ];
        month = months[date[1] - 1];
        let datebuff =
          date[3] + ":" + date[4] + " " + date[2] + " " + month + " " + date[0];
        finalDate = datebuff;
      } else {
        finalDate = "-";
      }

      return finalDate;
    },
    permission(parameter) {
      console.log("parameter", parameter);
      // return parameter
      if (parameter === "01") {
        return this.$t("keydetail.viewonly");
      } else if (parameter === "02" || parameter === "03") {
        return this.$t("keydetail.downloadonly");
      } else if (
        parameter === "04" ||
        parameter === "05" ||
        parameter === "06"
      ) {
        return this.$t("keydetail.edit_download");
      }
    },
  },
};
</script>
<style></style>
